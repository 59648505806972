<template>
  <div class="home">
    <navBar></navBar>
    <div class="content">
      <div class="md-headline welcome">Welcome {{ userLoggedIn.name }}</div>
      <!--      <div class="md-subheader">Your last login was {{ userLoggedIn.lastLogin }}</div>-->
<!--      <md-card>-->
<!--        <md-card-content>-->
<!--          <Analysis style="margin-bottom: 2em"></Analysis>-->
<!--        </md-card-content>-->
<!--      </md-card>-->
      <md-card>
        <md-table>
          <md-table-toolbar>
            <h1 class="md-title">Users</h1>
          </md-table-toolbar>

          <!--  Old Users-->
          <md-table-row v-for="(user, key) in users.oldUsers" v-bind:key="key">
            <md-table-cell md-label="Name">
              <md-field md-inline>
                <label>Name</label>
                <md-input v-model="user.name" @input="changeUser = true" required></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="Username">
              <md-field md-inline>
                <label>Username</label>
                <md-input v-model="user.username" @input="changeUser = true" required></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="E-Mail">
              <md-field md-inline>
                <label>E-Mail</label>
                <md-input v-model="user.email" @input="changeUser = true"></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button md-accent" @click="removeUser(0,key)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>

          <!--  New Users-->
          <md-table-row v-for="(user, key) in users.newUsers" v-bind:key="key">
            <md-table-cell md-label="Name">
              <md-field md-inline>
                <label>Name</label>
                <md-input v-model="user.name" @input="changeUser = true" required></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="Username">
              <md-field md-inline>
                <label>Username</label>
                <md-input v-model="user.username" @input="changeUser = true" required></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="E-Mail">
              <md-field md-inline>
                <label>E-Mail</label>
                <md-input v-model="user.email" @input="changeUser = true"></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="Password">
              <md-field md-inline>
                <label>Password</label>
                <md-input v-model="user.pwdHash" @input="changeUser = true" type="password"></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button md-accent" @click="removeUser(0,key)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-button class="md-icon-button md-raised margin md-primary"
                   @click="users.newUsers.push({'username': '','name': '','email': '','pwHash': ''})">
          <md-icon>add</md-icon>
        </md-button>
        <md-button class="md-icon-button md-raised margin md-accent" @click="userSaveChanges()" v-if="changeUser">
          <md-icon>save</md-icon>
        </md-button>
        <md-progress-spinner v-if="query" md-mode="indeterminate" class="margin" :md-diameter="38"
                             :md-stroke="2"></md-progress-spinner>
      </md-card>


    </div>
  </div>
</template>
<script>
/* eslint-disable */
import navBar from '@/components/NavBar.vue';
import Analysis from '@/components/Insights.vue';
import vueCookie from 'vue-cookie';
import axios from 'axios';
import config from '@/config/const.js'

export default {
  name: "Home",
  data() {
    return {
      config: config,
      userLoggedIn: {},
      users: {
        oldUsers: [],
        newUsers: []
      },
      changeUser: false,
      query: false
    }
  },
  methods: {
    getUser() {
      let me = this;
      me.users.newUsers = [];
      me.users.oldUsers = [];
      // axios.get('api/get.php?table=loginData')
      axios.get(me.config.SERVER_ADDRESS + '/users')
          .then(function (response) {
            response.data.forEach(function (user) {
              if (user.username === vueCookie.get("username")) {
                me.userLoggedIn = user;
              }
            });
            me.users.oldUsers = response.data;
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    removeUser(table, key) {
      let me = this;
      this.query = true;
      if (table === 0) {
        const user = this.users.oldUsers.splice(key, 1);
        axios.delete(me.config.SERVER_ADDRESS + '/users/' + user[0]._id)
            .then(function (response) {
              console.log(response.data);
            })
            .catch(function (error) {
              alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
            });
        this.query = false;
      } else if (table === 1) this.users.newUsers.splice(key, 1);

    },
    userSaveChanges() {
      this.query = true;
      let me = this;
      me.users.oldUsers.forEach(function (user) {
        axios.put(me.config.SERVER_ADDRESS + '/users/id/' + user._id,
            {
              user: user
            })
            .then(function (response) {
              console.log(response.data.message);
            })
            .catch(function (error) {
              alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
            });
      });
      me.users.newUsers.forEach(function (user) {
        axios.post(me.config.SERVER_ADDRESS + '/users/', {
          user: user
        })
            .then(function (response) {
              console.log(response.data.message);
            })
            .catch(function (error) {
              alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
            });
      });

      this.query = false;
      this.changeUser = false;
      this.getUser()
    }

  },
  components: {
    navBar, Analysis
  },
  mounted() {
    this.getUser();
  }
}
</script>
<style scoped>
.content {
  margin: 2% 5% 0 5%;
}

.welcome {
  margin-bottom: 10px;
  padding: 10px 0 10px 0;
}

.margin {
  margin: 1em;
}
</style>
